import * as React from "react";
import {graphql} from 'gatsby';
import Seo from '../components/Seo';
import Layout from '../components/layout/Layout';
import Divider from '../components/Divider';
import {useTranslation} from 'gatsby-plugin-react-i18next';

const LegalPage = () => {
  const {t} = useTranslation();

  return (
    <Layout>
      <Seo />
      <section className="text-gray-600 body-font mt-10">
        <div className="my-cont">
          <Divider title={t('sub.title')} />
          <div className="lg:w-2/3 w-full mx-auto overflow-auto">
            <table className="table-auto w-full">
              <tbody>
                <tr>
                  <td colSpan="2" className="px-4 py-3 tracking-wider font-semibold text-blue-900 bg-gray-100 rounded-t">{t('legal')}</td>
                </tr>
                <tr>
                  <td className="px-4 py-3 border-t border-gray-200">{t('company')}</td>
                  <td className="px-4 py-3 border-t border-gray-200">{t('company.name')}</td>
                </tr>
                <tr>
                  <td className="px-4 py-3 border-t border-gray-200">{t('representative')}</td>
                  <td className="px-4 py-3 border-t border-gray-200 ">{t('representative.ceo')}</td>
                </tr>
                <tr>
                  <td className="px-4 py-3 border-t border-gray-200">{t('registration')}</td>
                  <td className="px-4 py-3 border-t border-gray-200">{t('registration.number')}</td>
                </tr>
                <tr>
                  <td colSpan="2" className="px-4 py-3 tracking-wider font-semibold text-blue-900 bg-gray-100 rounded-t">{t('address')}</td>
                </tr>
                <tr>
                  <td className="px-4 py-3 border-t border-gray-200">{t('danangoffice')}</td>
                  <td className="px-4 py-3 border-t border-gray-200">{t('danangoffice.address')}</td>
                </tr>
                <tr>
                  <td className="px-4 py-3 border-t border-gray-200">{t('headquarters')}</td>
                  <td className="px-4 py-3 border-t border-gray-200">{t('headquarters.address')}</td>
                </tr>
                <tr>
                  <td colSpan="2" className="px-4 py-3 tracking-wider font-semibold text-blue-900 bg-gray-100 rounded-t">{t('contact')}</td>
                </tr>
                <tr>
                  <td className="px-4 py-3 border-t border-gray-200">{t('inquiry')}</td>
                  <td className="px-4 py-3 border-t border-gray-200"><a href="mailto:hello@iohub.tech">hello@iohub.tech</a></td>
                </tr>
                <tr>
                  <td className="px-4 py-3 border-t border-gray-200">{t('sales')}</td>
                  <td className="px-4 py-3 border-t border-gray-200"><a href="mailto:sales@iohub.tech">sales@iohub.tech</a></td>
                </tr>
                <tr>
                  <td className="px-4 py-3 border-t border-gray-200">{t('phone')}</td>
                  <td className="px-4 py-3 border-t border-gray-200"><a href={`tel:${t('fmenu.s.phone')}`}>{t('fmenu.s.phone')}</a></td>
                </tr>
                <tr>
                  <td colSpan="2" className="px-4 py-3 tracking-wider font-semibold text-blue-900 bg-gray-100 rounded-t">{t('official')}</td>
                </tr>
                <tr>
                  <td className="px-4 py-3 border-t border-gray-200">Vimeo</td>
                  <td className="px-4 py-3 border-t border-gray-200"><a href="https://vimeo.com/iohubtech" target="_blank" rel="noreferrer">https://vimeo.com/iohubtech</a></td>
                </tr>
                <tr>
                  <td className="px-4 py-3 border-t border-gray-200">Facebook</td>
                  <td className="px-4 py-3 border-t border-gray-200"><a href="https://www.facebook.com/iohub.tech" target="_blank" rel="noreferrer">https://www.facebook.com/iohub.tech</a></td>
                </tr>
                <tr>
                  <td className="px-4 py-3 border-t border-gray-200">Twitter</td>
                  <td className="px-4 py-3 border-t border-gray-200"><a href="https://twitter.com/iohubtech" target="_blank" rel="noreferrer">https://twitter.com/iohubtech</a></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </Layout>
  )
};

export default LegalPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}, ns: {in: ["translation", "legal"]}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
